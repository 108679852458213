@media (prefers-color-scheme: dark) {
  @mixin hover-focus() {
    &:hover,
    &:focus {
      @content;
    }
  }

  @mixin hover() {
    &:hover {
      @content;
    }
  }

  $blue: #79ABFF ;
  $link-color: #aeaeae;
  $link-hover-color: darken($link-color, 15%);
  $white-100: #EEEEEE;
  $white-100-darken: darken($white-100, 15%);

  $red-200: #D25252;
  $dark-100: #2b2b2b;
  $dark-200: #323232;
  $dark-300: #3c3f41;
  $dark-400: #424445;
  $dark-500: #75797b;

  body, html {
    background-color: $dark-100;
  }

  body {
    a, .event-tabs button.config-option-features-button {
      color: $link-color;

      @include hover() {
        color: $link-hover-color;
      }
    }

    .btn-link {
      color: $link-color;

      @include hover() {
        color: $link-hover-color;
      }
    }

    $navbar-light-color: rgba($white-100, .5);
    $navbar-light-hover-color: rgba($white-100, .7);
    $navbar-light-active-color: rgba($white-100, .9);
    $navbar-light-disabled-color: rgba($white-100, .3);
    $navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    $navbar-light-toggler-border-color: rgba($white-100, .1);
    $navbar-light-brand-color: $navbar-light-active-color;
    $navbar-light-brand-hover-color: $navbar-light-active-color;

    .navbar-light {
      .navbar-brand {
        color: $navbar-light-brand-color;

        @include hover-focus() {
          color: $navbar-light-brand-hover-color;
        }
      }

      .navbar-nav {
        .nav-link {
          color: $navbar-light-color;

          @include hover-focus() {
            color: $navbar-light-hover-color;
          }

          &.disabled {
            color: $navbar-light-disabled-color;
          }
        }

        .show > .nav-link,
        .active > .nav-link,
        .nav-link.show,
        .nav-link.active {
          color: $navbar-light-active-color;
        }
      }

      .navbar-toggler {
        color: $navbar-light-color;
        border-color: $navbar-light-toggler-border-color;
      }

      .navbar-toggler-icon {
        background-image: escape-svg($navbar-light-toggler-icon-bg);
      }

      .navbar-text {
        color: $navbar-light-color;

        a {
          color: $navbar-light-active-color;

          @include hover-focus() {
            color: $navbar-light-active-color;
          }
        }
      }
    }

    $nav-tabs-border-color: $dark-400;
    $nav-tabs-link-hover-border-color: $dark-400;
    $nav-tabs-link-active-color: $white-100;
    $nav-tabs-link-active-bg: $dark-400;
    $nav-tabs-link-active-border-color: $dark-400;

    .nav-tabs {
      border-color: $nav-tabs-border-color;

      @include hover-focus() {
        border-color: $nav-tabs-link-hover-border-color;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      color: $nav-tabs-link-active-color;
      background-color: $nav-tabs-link-active-bg;
      border-color: $nav-tabs-link-active-border-color;
    }

    $input-bg: #ddd;
    $input-color: #495057;
    $input-border-color: #ced4da;
    $input-focus-bg: $input-bg;
    $input-focus-color: $input-color;

    .form-control, .form-control-lg {
      color: $input-color;
      background-color: $input-bg;
      border-color: $input-border-color;
    }

    .form-control-plaintext {
      color: $white-100;
    }

    .form-control:focus, .form-control-lg:focus {
      box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
    }

    select.form-control, select.form-control-lg {
      &:focus::-ms-value {
        color: $input-color;
        background-color: $input-bg;
      }
    }


    .btn-primary {
      color: #fff;
      background-color: $blue;
      border-color: $blue;
    }

    .btn-primary:hover {
      color: #fff;
      background-color: darken($blue, 10%);
      border-color: darken($blue, 15%);
    }

    .btn-primary:focus, .btn-primary.focus {
      color: #fff;
      background-color: darken($blue, 10%);
      border-color: darken($blue, 15%);
      box-shadow: 0 0 0 0.2rem rgba(121, 171, 255, 0.5);
    }

    .btn-primary.disabled, .btn-primary:disabled {
      color: #fff;
      background-color: $blue;
      border-color: $blue;
    }

    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: darken($blue, 10%);
      border-color: darken($blue, 15%);
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 128, 255, 0.5);
    }


    .list-group-item {
      color: $link-color;
      background-color: $dark-200;
      border-color: $dark-100;

      &.active {
        color: $white-100;
        background-color: $dark-300;
        border-color: $dark-100;
      }
    }


    color: $white-100;

    .style-card, .card-box {
      background-color: $dark-200;
    }

    .accordion > .card {
      background: $dark-100;

      .card-header {
        background: $dark-300;
      }

      .card-body {
        background: $dark-200;
      }

      .btn-link {
        color: $white-100;
        margin-left: auto;

        @include hover() {
          color: $white-100-darken;
        }
      }
    }

    .bg-white {
      background-color: $dark-300 !important;
    }

    .text-dark {
      color: $white-100 !important;
    }

    #event-list .refunded {
      color: $red-200;
    }

    #event-list div.desktop-view table td div.event-actions .dropdown .material-icons {
      color: $white-100;
    }

    .table {
      color: $white-100;

      td, th {
        border-color: #7d7d7d;
      }

      thead th {
        border-color: #7d7d7d;
      }

      .btn:not(.btn-light) {
        color: $white-100;
      }

      tr.markable-tr.bg-light {
        background-color: $dark-400 !important;
      }
    }

    .page-item.active .page-link {
      background-color: $blue;
      border-color: $blue;
    }

    .page-link {
      color: $blue;
      background: $dark-400;
      border-color: $dark-100;
    }

    .page-item.disabled .page-link {
      background: $dark-400;
      border-color: $dark-100;
      color: #6c757d;
    }

    #footer.bg-light {
      background-color: $dark-300 !important;
    }

    #organizer-admin-header {
      background-color: $blue;
    }

    .sidebar {
      background-color: $dark-200;
    }

    #admin-content-frame {
      background-color: $dark-100;
    }

    #show-event,
    #gift-card-form {
      #event-description {
        background: none;
        box-shadow: none;
        padding: 0;

        .fadeout {
          background-image: linear-gradient(rgba(255, 255, 255, 0) 0, #323232 100%);
        }

        #fadeout-button-show {
          background: $dark-300;
          color: $white-100;
        }
      }

      .event-organizer-info {
        .event-organizer-info-name a {
          color: $white-100;
        }
      }
    }
    
    #gift_card_order_register_form{
      .amount-btn{
        color: $white-100;
      }
    }

    .event-public-list-organizer-icon, .show-event-where-icon, .show-event-when-start-icon {
      color: $white-100;
    }

    .ticket-qty-container .btn {
      margin-bottom: 2px;
    }

    .modal-content {
      background: $dark-200;

      .close {
        color: $white-100;
        text-shadow: 0 1px 0 #5c5c5c;
      }
    }

    .modal-header {
      border-bottom: 1px solid #4d4d4d;
    }

    .modal-footer {
      border-top: 1px solid #4d4d4d;
    }

    .btn-light {
      color: #666666;
    }

    #event-ticket-category-list {
      .container-category-list-wrapper {
        border-left: 10px solid #666666;

        .event-ticket-category-list:not(.restriction-code-ticket-category) {
          border: none;

          &:last-child {
            border-bottom: 1px solid #888888;
          }
        }
      }
    }

    .event-ticket-category-list {
      background: none;
    }

    .event-ticket-category-list:not(.restriction-code-ticket-category) {
      border-bottom: 1px solid #888888;
    }

    #event-ticket-category-area-zone {
      .event-ticket-category-area-zone {
        background: none;
        border-bottom: 1px solid #888888;
      }
    }

    #event-ticket-categories {
      &.area-zone-fixed-center {
        @include media-breakpoint-up(md) {
          background-color: $dark-200;
        }
      }
    }

    #order-count-down-timer {
      color: $white-100;
    }

    #reservation-alert {
      color: #333333;
      background-color: #dddddd;
      border: none;
    }

    .dropdown-menu.show {
      background: $dark-400;

      .dropdown-item {
        color: $white-100;
      }

      .dropdown-item:focus, .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
        background-color: $dark-500;
        color: $white-100;
      }
    }

    .form-control.picker__input::placeholder, .form-control-lg.picker__input::placeholder {
      opacity: 0.7;
    }

    #order-count-down .form-control, #order-count-down .form-control-lg, #order-summary table, #queue-count-down .form-control, #queue-count-down .form-control-lg {
      background: none;
      border-color: #7d7d7d;
    }

    .select2-selection__rendered, .select2-results__option {
      color: $input-color;
      background-color: $input-bg;
    }

    .select2-container--bootstrap4 .select2-results__option--highlighted, .select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected="true"],
    body .form-control option:checked {
      background: $link-color;
      color: #333;
    }

    .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
      background: #fefefe;
      padding-right: 24px;
    }

    .style-card-image-container .no-image {
      background-image: linear-gradient(to bottom right, #616161, rgba(0, 105, 217, .3));
    }

    .style-card-footer {
      .event-card-footer-btn {
        padding: 0.675rem .75rem;

        &.btn-primary {
          color: $white-100;
          background-color: $dark-300;
          border-color: $dark-300;
        }

        &.btn-primary:hover {
          color: #fff;
          background-color: $dark-400;
          border-color: $dark-400;
        }

        &.btn-primary:focus, .btn-primary.focus {
          color: #fff;
          background-color: $dark-400;
          border-color: $dark-400;
          box-shadow: 0 0 0 0.2rem rgba(106, 104, 104, 0.5);
        }
      }
    }

    #scene-wrapper-order, .scene-wrapper {
      border: 1px solid $dark-300;
    }

    $colors: #2b908f #90ee7e #f45b5b #7798BF #aaeeee #ff0066 #eeaaee #55BF3B #DF5353 #7798BF #aaeeee;

    // Neutral colors
    $neutral-color-100: #fff;
    $neutral-color-80: #E0E0E3;
    $neutral-color-60: #E0E0E3;
    $neutral-color-40: #666;
    $neutral-color-20: #606063;
    $neutral-color-10: #707073;
    $neutral-color-5: #505053;

    .highcharts-background {
      fill: $dark-200;
    }

    // Titles
    .highcharts-title, .highcharts-subtitle {
      text-transform: uppercase;
      fill: $white-100;
    }

    // Tooltip
    .highcharts-tooltip text {
      fill: #333;
    }

    // Range-selector
    .highcharts-range-selector-buttons text {
      fill: $white-100;
    }

    // Axes
    .highcharts-yaxis-grid {
      stroke-width: 1px;
    }

    .highcharts-axis-labels, .highcharts-axis-title {
      fill: $white-100;
    }

    // Navigator
    .highcharts-navigator .highcharts-navigator-handle {
      fill: $neutral-color-40;
      stroke: #aaa;
    }

    .highcharts-navigator .highcharts-navigator-outline {
      stroke: #CCC;
    }

    .highcharts-navigator .highcharts-navigator-xaxis .highcharts-grid-line {
      stroke: $neutral-color-5;
    }

    .highcharts-grid-line {
      stroke: #686868;
    }

    .highcharts-axis-line {
      stroke: #a4adc2;
    }

    // Scrollbar
    .highcharts-scrollbar .highcharts-scrollbar-rifles {
      stroke: $neutral-color-100;
    }

    .highcharts-scrollbar .highcharts-scrollbar-button {
      stroke: #606063;
      fill: #606063;
    }

    .highcharts-scrollbar .highcharts-scrollbar-arrow {
      fill: #CCC;
    }

    .highcharts-scrollbar .highcharts-scrollbar-thumb {
      fill: #808083;
      stroke: #808083;
    }

    // Navigation
    .highcharts-contextbutton .highcharts-button-symbol {
      stroke: #DDDDDD;
    }

    .highcharts-contextbutton {
      fill: #6c757d;
    }

    .highcharts-contextbutton:hover {
      fill: #5a6268;
      stroke: #5a6268;
    }

    .highcharts-legend-item text {
      fill: $white-100;
      transition: fill 250ms;
    }

    .highcharts-legend-item:hover text {
      fill: white;
    }

    .highcharts-color-1 {
      fill: #c4c761;
      stroke: #c4c761;
    }

    .highcharts-stack-labels {
      fill: $white-100;
    }

    .product-group-list-container {
      .list-group-item-action:focus, .list-group-item-action:hover {
        color: $white-100;
        background: $dark-300;
      }
    }

  }

}