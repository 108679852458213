.dashboard-pie-charts {
  .highcharts-root {
    height: 300px;

    .highcharts-title {
      text-transform: capitalize;
      font-size: 2rem;
    }

    .highcharts-tooltip {
      font-size: 1rem;

      .highcharts-header {
        font-size: 1rem;
      }
    }
  }
}