.event-accommodation-categories {
  .event-accommodation-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    .ticket-qty-container-wrapper {
      display: none;
    }

    .row.event-ticket-category-list {
      background: #3c3f41;
      cursor: pointer;
    }
  }

  #div_order_buttons {
    #order-next-button {
      display: none !important;
    }
  }
}