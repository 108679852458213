
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color: #343C49;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

#organizer-admin-header {
  /*background-color: #3F51B5;*/
  background-color: #3F51B5;
}

.sidebar ul.nav > li > a {
  color: rgba(255, 255, 255, 0.6);
  display: block;
}

.sidebar ul.nav > li > a:hover {
  color: #ffffff;
  text-decoration: none;
}

#admin-content-frame {
  background-color: #EBEBEB;

  #admin-content {
    max-width: 1300px;

    #event_register_form {
      max-width: 780px;
    }
  }

  #admin-content.collapsed {
    max-width: 100%;
    padding-left: 50px;
  }

  footer {
    margin: 0 -15px;
    background-color: #FFFFFF;
  }

  .flex-grow-1:not(#scene-part-wrapper) {
    margin-top: 66px;
  }

  .slider {
    img {
      border-radius: 3px;
    }
  }

}

.card-box {
  padding: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.picker__frame {
  padding: 2px;
}

.key-figures {
  &-text {
    font-size: 1.2rem;
  }

  &-numbers {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.admin-organizer-about {
  background-color: #F8F9FA;
  padding: 0.8rem;
  max-height: 200px;
  overflow: auto;
}

.datepicker-start-date-statistic-hidden {
  display: none;
}

.delete-picture-checkbox {
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    position: relative;
  }
}

.mobile-view {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.desktop-view {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

#event-sales-ul {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    margin-left: 0;
  }
}

.refunded-ticket {
  color: #CC0000;
}