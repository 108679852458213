$user-tickets-gap-size-mobile: 75px;
$user-tickets-gap-size-desktop: 55px;

#user_tickets_card_box {
  .ticket-container:not(:first-child) {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px dotted #CCC;
  }

  .ticket-container.ticket-type-normal:not(:first-child) {
    padding-top: $user-tickets-gap-size-desktop;
    margin-top: $user-tickets-gap-size-desktop;
    @include media-breakpoint-down(md) {
      padding-top: $user-tickets-gap-size-mobile;
      margin-top: $user-tickets-gap-size-mobile;
    }
  }

  .ticket-container.ticket-type-normal:last-child {
    .ticket-qr-container {
      margin-bottom: $user-tickets-gap-size-desktop;

      @include media-breakpoint-down(md) {
        margin-bottom: $user-tickets-gap-size-mobile;
      }
    }
  }

  .ticket-qr-container {
    width: 100%;
    margin-top: $user-tickets-gap-size-desktop;
    text-align: center;
    box-sizing: border-box;

    @include media-breakpoint-down(md) {
      margin-top: $user-tickets-gap-size-mobile;
    }

    .ticket-qr-wrapper {
      width: 100%
    }
  }

  .accordion > .card {
    .btn-link {
      display: flex;

      &:after {
        display: flex;
        flex-shrink: 0;
        margin-left: auto;
        font-family: 'Material Icons';
        content: "keyboard_arrow_up";
        -webkit-font-feature-settings: 'liga';
      }

      &.collapsed {
        &:after {
          content: "keyboard_arrow_down";
        }
      }
    }
  }
}