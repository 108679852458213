#pos-header {
  height: 1rem;
}

#pos-footer-logo {
  position: fixed;
  bottom: 10px;
  left: 10px;

  img {
    max-height: 30px;
  }
}

.pos-event {
  .btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
  }

  #order-next-button {
    position: fixed;
    left: 15px;
    bottom: 70px;
  }

  #pos-event-left {
    position: sticky;
    top: 1rem;
  }

  input, .input-group-text {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
  }

  h1 {
    font-size: 2rem;
  }

  .event-ticket-category-list .ticket-qty-container {
    input.ticket-to-order {
      width: 6rem;
    }
  }
}

body .pos-event .ticket-qty-container .btn {
  margin-bottom: .3rem;
}