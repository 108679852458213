#organizer-customers-filters {
  .form-row {
    gap: 15px;

    @include media-breakpoint-down(sm) {
      gap: 5px !important;
      flex-direction: column;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      &.form-group-row {
        flex-direction: row;
      }

      &.form-group-grow {
        flex-grow: 1;
      }

      #organizer-customers-download-btn,
      #organizer-customers-emails-download-btn {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      @include media-breakpoint-down(sm) {
        flex-grow: 1;
      }
    }
  }
}

#organizer-customers-table {
  thead tr {
    th {
      &.sort:hover {
        cursor: pointer;
      }

      .material-icons {
        vertical-align: sub;
        padding-bottom: 0;

        &.material-icons-asc {
          transform: rotate(180deg);
        }
      }
    }
  }
}