div.key-figures div#key-figures-number-of-sold-tickets {
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

div#event-information div.form-check-inline {
  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

div#event-information div.form-check {
  padding-left: 0.85rem;
}

.graph-event-recurring {
  .highcharts-point.highcharts-point-hover {
    cursor: pointer;
  }
}

.graph-label-link {
  color: inherit;
}

.event_statistic_form {
  .flex-right-column {
    display: flex;
    margin-left: auto;
    flex-direction: column;
    margin-right: 15px;

    .material-icons {
      font-size: 24px;
      color: inherit;
    }
  }
}