#gift-card-form{
  .event-organizer-info {
    padding-top: 15px;

    .event-organizer-info-organizer {
      font-size: 13px;
      line-height: 1.2;
    }

    .event-organizer-info-logo {
      margin-top: 10px;

      img {
        max-height: 120px;
      }
    }

    .event-organizer-info-name a {
      font-size: 24px;
      line-height: 1.7;
    }
  }
}

#gift_card_order_register_form{
  .amount-btn{
    border: 1px solid $blue;
  }

  .btn-selected {
    background-color: $blue;
    color: $white;
  }
}