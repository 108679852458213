.order-info-wrapper span {
  border: 2px solid #46a0ff;
  display: inline-block;
  padding: 10px;
}

#area-zone-seat-icon {
  cursor: pointer;
}

#area-zone-seat-link {
  cursor: pointer;
}

#event-ticket-categories {
  &.area-zone-fixed-center {
    @include media-breakpoint-up(md) {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      padding: 20px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: 3px;
      background-clip: padding-box;
      margin-bottom: 20px;
      background-color: #ffffff;
      width: 100%;
      max-width: 1110px;
      max-height: 100%;
      overflow: auto;
    }
  }
}

#order-form {
  #checkout-customer-user-info {
    font-size: 20px;
  }
}

#event-ticket-category-statistic {
  .container-category {
    cursor: pointer;

    .container-category-sub-categories tr td {
      text-align: left;
    }
  }

  tr.container-category > td:nth-of-type(1):before {
    font-family: "Material Icons";
    content: "\e5cc";
    vertical-align: -10%;
    padding-right: 4px;
  }

  tr.container-category.open > td:nth-of-type(1):before {
    content: "\e313";
  }
}