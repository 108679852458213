#event-name-row {
  &.event-preamble-exists h1 {
    margin-bottom: .5rem;
  }
}

#show-event {
  #event-description {
    position: relative;

    &.view-all {
      @include media-breakpoint-down(sm) {
        max-height: none;
      }

      #fadeout-button-show,
      .fadeout {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      max-height: 200px;
      overflow: hidden;
    }


    .fadeout {
      position: absolute;
      bottom: 35px;
      left: 0;
      height: 140px;
      width: 100%;
      background-image: linear-gradient(
                      rgba(255, 255, 255, 0) 0%,
                      rgb(255, 255, 255) 100%
      );
    }

    #fadeout-button-show {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #FFFFFF;
      padding-bottom: 10px;

      #fadeout-button {
        font-size: 1rem;
      }
    }
  }

  .subevent-calendar-btn {
    max-width: 100px;
  }

  #event-subevent-calendar-wrapper {
    margin: 0;
  }

  .event-organizer-info {
    padding-top: 15px;

    .event-organizer-info-organizer {
      font-size: 13px;
      line-height: 1.2;
    }

    .event-organizer-info-logo {
      margin-top: 10px;

      img {
        max-height: 120px;
      }
    }

    .event-organizer-info-name a {
      font-size: 24px;
      line-height: 1.7;
    }
  }
}

#event-list {

  .table-responsive {
    min-height: 200px;
  }

  .event-list-image-container {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .refunded {
    font-weight: bold;
    color: #CC0000;
  }

  div.desktop-view {
    table {
      td {
        div.event-actions {

          .dropdown-menu {
            text-align: center;
          }

          .dropdown .material-icons {
            color: #000;
            font-size: 32px;
          }
        }
      }
    }
  }
}

div#admin-content-frame {
  div#admin-content {
    div.welcome-message {
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
      @include media-breakpoint-up(sm) {
        font-size: 1.5rem;
      }
      font-weight: bold;
      text-align: center;
      color: #999;
    }

    #event_register_form {
      #event_subevent_periods {
        list-style: none;
        padding-left: 0;

        .event_subevent_period_info_btn {
          text-decoration: none;
        }
      }
    }
  }
}

.event-subevent-timeslot-arrow-back-icon {
  position: absolute;
  margin-top: 6px;
}

.event-subevent-calendar-box {
  margin-right: 30px;

  &-icon {
    position: absolute;
    margin-top: 6px;
  }
}

.event-subevent-list {

  &-few-ticket-left {
    color: #FFAA00;
  }

  &-sold-out {
    color: #FF0000;
  }

}

#subevent-list {
  .refunded {
    font-weight: bold;
    color: #CC0000;
  }
}

#event_statistic_form {
  .material-icons {
    color: #000;
    font-size: 32px;
    cursor: pointer;
  }
}

#event-ticket-categories {
  .pagination {
    margin-left: 10px
  }
}

.style-card-footer {
  .event-card-footer-btn {
    padding: 0.675rem .75rem;
  }
}

.delete-event-entry-point {
  cursor: pointer;
}

#category-container-info {
  font-style: italic;
  font-size: 0.9rem;
  opacity: 0.6;
}