#event_ticket_category_table {

  .material-icons {
    font-size: 24px;
  }

  td {
    vertical-align: middle;
  }

  tr td:last-child {
    width: 140px;
  }

}


#ticket_category_container_form_wrapper {
  .ticket-category-container-selection-group {
    border-bottom: 1px solid #495057;

    .container-selection-group-left {
      border-right: 1px solid #495057;
      border-radius: 6px;

      .select2.select2-container {
        width: calc(100% - 60px) !important;
        display: inline-block !important;
      }

      .container-group-category-remove {
        margin-top: -4px;
      }
    }

    .container-selection-group-right {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    .container-selection-label-max-tickets {
      display: inline-block;
    }

    .container-selection-label-number-of-tickets {
      display: none;
    }
  }

  &.sub-products-active .ticket-category-container-selection-group {
    .category-container-group-category-sub-add {
      display: none;
    }

    .container-group-category-remove {
      display: none;
    }

    .container-selection-label-max-tickets {
      display: none;
    }

    .container-selection-label-number-of-tickets {
      display: inline-block;
    }
  }
}


#modal-event-ticket-category-subevent-deviation {
  .modal-dialog.modal-xxl {
    @include media-breakpoint-up(md) {
      max-width: 98vw;
    }
  }

  table#event-ticket-category-deviation-list-table {
    .category-list {
      color: #aeaeae;
    }
  }

  #event_subevent_deviation_register_form {
    .category-deviation-text {
      display: flex;
      align-items: center;
    }

    .category-deviation {
      background-color: #28a745;
      color: #FFFFFF;
    }

    #event_subevent_deviation_register_form_table {
      tr {
        td:first-child {
          min-width: 125px;
          padding-top: 44px;
        }

        td {
          .deviation-date-text {
            display: inline-block;
            padding: 0 13px;
          }

          input {
            min-width: 80px;
          }
        }
      }
    }
  }
}